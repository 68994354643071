$(function(){
    initMobileMenu();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 5000);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();


    function initSearchBar() {

    }

    //$('#menu nav li:has(ul)').doubleTapToGo();

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $.fn.matchHeight._update()
    });

    $('#back-top').click(function(){
        $('body, html').stop().animate({
            scrollTop: 0
        }, 800);
    });
});

function initMobileMenu() {
    var menu = $('#menu nav').clone(),
        mobileMenu = $('#mobile-menu');
    mobileMenu.append(menu.html()).mmenu({
        offCanvas: {
            position: "left"
        }
    });
}

(function(){
})();